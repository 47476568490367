import axios from 'axios';
import router from '../router';
import { Message, Loading } from 'element-ui';
let baseURL = process.env.VUE_APP_HOST;//eslint-disable-line
const request = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    'content-type': 'application/json',
  },
});
let requestCount = 0;
let loading;
// 请求拦截器
request.interceptors.request.use(config => { // 拦截请求，做统一处理
  const token = localStorage.getItem('token');
  // 在每个http header都加上token
  config.headers.Authorization = 'bearer ' + token;
  if (requestCount === 0) {
    loading = Loading.service({
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
    });
  }
  if (config.method !== 'get') {
    config.data = config.data || {};
  }
  requestCount++;
  return config;
}, err => { // 失败
  return Promise.reject(err);
});
// 响应拦截器
request.interceptors.response.use(response => { // 拦截响应，做统一处理
  requestCount--;
  if (requestCount === 0) {
    loading.close();
  }
  if (response.config.url === 'http://upload.qiniup.com') {
    return response.data;
  } else if (response.data.heads && [6001, 200, 6002].includes(response.data.heads.code)) {
    return response.data;
  } else if (response.config?.url === '/xiaozi-saas/wireless/tenant/queryReportPageExcel') {
    return { data: response.data, headers: response.headers };
  } else if (response.data.heads && [4012, 506].includes(response.data.heads.code)) {
    localStorage.clear();
    router.push('/login');
    return Promise.reject(new Error('token已过期'));
  } else if (response.data.repCode && response.data.repCode === '0000' && response.data.success) {
    // 滑动验证码成功
    return response.data;
  } else if (response.data.repCode && response.data.repCode !== '0000' && !response.data.success) {
    // 滑动验证码失败
    Message({
      showClose: true,
      message: response.data.repMsg,
      type: 'error',
      dangerouslyUseHTMLString: true,
    });
    return response.data;
  } else {
    Message({
      showClose: true,
      message: response.data.heads.message,
      type: 'error',
    });
    return Promise.reject(response.data.heads.message);
  }
}, err => { // 无响应时的处理
  requestCount--;
  if (requestCount === 0) {
    loading.close();
  }
  let defaultMsg = '系统异常';
  if (err.response && err.response.data && err.response.data.heads) {
    defaultMsg = err.response.data.heads.message || defaultMsg;
  }
  Message({
    showClose: true,
    message: defaultMsg,
    type: 'error',
  });
  return Promise.reject((err && err.response && err.response.status) || defaultMsg);
});
export default request;
